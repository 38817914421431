import React, { useEffect } from "react";
import { useState } from "react";
import "./Titlebar.css";
import Popup from "reactjs-popup";
import { IoMdContact } from "react-icons/io";
import print from "print-js";
import { Link } from "react-router-dom";
import { ACCESS_TOKEN, SERVER_URL } from "../config/config";
import Modal from "./Modal";
import axios from "axios";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const Titlebar = ({
  sessionObject,
  checkedList,
  cewbCheckedList,
  autoExtension,
  setAutoExtension,
}) => {
  const [show, setShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flagEwb, setFlagEwb] = useState(false);
  const [flagCewb, setFlagCewb] = useState(false);
  const [flagPanAadharLink, setFlagPanAadharLink] = useState(false);
  const [flagVehicleFastag, setFlagVehicleFastag] = useState(false);
  const [flagGenerateCewb, setFlagGenerateCewb] = useState(false);
  const [flagUpdateEwbTransporter, setFlagUpdateEwbTransporter] =
    useState(false);
  const [toggleMode, setToggleMode] = useState();
  const [flagChangePassword, setFlagChangePassword] = useState(false);
  const [flagRecharge, setFlagRecharge] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [pan_no, setPan_no] = useState();
  const [VehicleFastag, setVehicleFastag] = useState();
  const [ewbNumber, setEwbNumber] = useState();
  const [cewbNumber, setCewbNumber] = useState();
  const [ewb_nos, setEwb_nos] = useState([]);
  const [vehicle_no, setVehicle_no] = useState("");
  const [mode_of_transport, setMode_of_transport] = useState("road");
  const [transporter_document_number, setTransporter_document_number] =
    useState("");
  const [transporter_document_date, setTransporter_document_date] =
    useState("");
  const [place_of_consignor, setPlace_of_consignor] = useState("");
  const [state_of_consignor, setState_of_consignor] = useState("");
  const [transporterGst, setTransporterGst] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [respData, setRespData] = useState();
  const [rows, setRows] = useState([]);

  const fields = [
    {
      label: "EWB numbers <span style='color: red;'>**</span>",
      placeholder: "Enter EWB numbers",
      value: ewb_nos,
      onChange: (e) => setEwb_nos(e.target.value),
    },
    {
      label: "Vehicle number <span style='color: red;'>**</span>",
      placeholder: "Enter vehicle number",
      value: vehicle_no,
      onChange: (e) => setVehicle_no(e.target.value),
    },
    {
      label: "Mode of transport",
      placeholder: "Enter mode of transport",
      value: mode_of_transport,
      onChange: (e) => setMode_of_transport(e.target.value),
    },
    {
      label: "Transporter document number",
      placeholder: "Enter transporter document number",
      value: transporter_document_number,
      onChange: (e) => setTransporter_document_number(e.target.value),
    },
    {
      label: "Transporter document date",
      placeholder: "Enter transporter document date",
      value: transporter_document_date,
      onChange: (e) => setTransporter_document_date(e.target.value),
    },
    {
      label: "Place of consignor <span style='color: red;'>**</span>",
      placeholder: "Enter place of consignor",
      value: place_of_consignor,
      onChange: (e) => setPlace_of_consignor(e.target.value.toUpperCase()),
    },
    {
      label: "State of consignor <span style='color: red;'>**</span>",
      placeholder: "Enter state of consignor",
      value: state_of_consignor,
      onChange: (e) => setState_of_consignor(e.target.value.toUpperCase()),
    },
  ];

  const columnns = React.useMemo(
    () => [
      {
        Header: "Sr No.",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Vehicle No.",
        width: "500px",
        minWidth: "10px",
        height: "500px",
        canFilter: true,
      },

      {
        Header: "Vehicle Type",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "TollPlaza Name",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Lane Direction",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Reader Read-Time",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "To",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Consignor",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Consignee",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "CEWB",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },

      {
        Header: "Truck No",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Expired?",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },
      {
        Header: "Stopped?",
        width: "100px",
        minWidth: "10px",
        canFilter: true,
      },
    ],
    []
  );

  var ACCESS_TOKEN = null;

  useEffect(() => {
    setEwbNumber(checkedList[0]);
    setCewbNumber(cewbCheckedList[0]);
    setEwb_nos(checkedList);
  }, [checkedList]);

  useEffect(() => {
    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    fetch(SERVER_URL + "/eway/get_auto_extension_condition/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: ACCESS_TOKEN,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAutoExtension(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const handleSubmitChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      return;
    }
    if (newPassword === oldPassword) {
      return;
    }

    try {
      const response = await fetch("ADD_API_ENDPOINT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        // Password changed successfully
        setFlagChangePassword();
      } else {
        // Password change failed
      }
    } catch (error) {}
  };
  const [recharge, setRecharge] = useState(false);

  const showPopup = () => {
    setDropdownOpen(false);
    setShow(!show);
  };

  const toggleDropdown = () => {
    setShow(false);
    setDropdownOpen(!dropdownOpen);
  };

  const showFlagEwb = () => {
    setFlagEwb(!flagEwb);
    setToggleMode("ewb");
    if (checkedList.length > 1) {
      alert("Select only one option");
    }
  };

  const showFlagCewb = () => {
    setFlagCewb(!flagCewb);
    setToggleMode("cewb");
    if (checkedList.length > 1) {
      alert("Select only one option");
    }
  };

  const showFlagPanAadharLink = () => {
    setFlagPanAadharLink(!flagPanAadharLink);
    setToggleMode("panAadharLink");
  };

  const showFlagVehicleFastag = () => {
    setFlagVehicleFastag(!flagVehicleFastag);
    setToggleMode(!flagVehicleFastag);
  };

  const showFlagGenerateCewb = () => {
    setFlagGenerateCewb(!flagGenerateCewb);
  };
  const showFlagUpdateEwbTransporter = () => {
    setFlagUpdateEwbTransporter(!flagUpdateEwbTransporter);
  };

  const showPopupRecharge = () => {
    setShow(!show);
    setFlagRecharge(!flagRecharge);
  };
  const showPopupChangePassword = () => {
    setShow(!show);
    setFlagChangePassword(!flagChangePassword);
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };
  const logout = () => {
    sessionObject.saveSessionVariableByField("access_token", "");
    sessionStorage.removeItem("login");
    sessionStorage.setItem("login_flag", false);
  };
  const contentStyle = {
    maxWidth: "600px",
  };
  const contentTableStyle = {
    // maxWidth: "600px",
    width: "900px",
    height: "500px",
    padding: "10px",
  };
  const handleOptionChange = async (e) => {
    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");
    const newValue = parseInt(e.target.value);
    setAutoExtension({ ...autoExtension, auto_extension: newValue });

    try {
      const response = await fetch(
        SERVER_URL + "/eway/stop_companywise_extension/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ACCESS_TOKEN,
          },
          body: JSON.stringify({ status_to_update: newValue }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePanAadharLink = async () => {
    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    try {
      const checkPanAdharLinkStatusResponse = await fetch(
        `${SERVER_URL}/company/check_pan_adhar_link_status?pan_no=${pan_no}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ACCESS_TOKEN,
          },
        }
      );

      if (checkPanAdharLinkStatusResponse.ok) {
        const linkStatusData = await checkPanAdharLinkStatusResponse.json();
        let newObject = {
          pan_no: pan_no,
          pan_link_status: "1",
          pan_link_status_message: linkStatusData.message,
          pan_link_status_code: linkStatusData.code,
        };

        if (parseInt(linkStatusData.code) === 1001) {
          newObject.pan_adhar_link_status = "1";
        } else if (parseInt(linkStatusData.code) === 1002) {
          newObject.pan_adhar_link_status = "0";
        } else if (parseInt(linkStatusData.code) === 1003) {
          newObject.pan_adhar_link_status = "2";
        } else if (parseInt(linkStatusData.code) === 1000) {
          newObject.pan_adhar_link_status = null;
        } else if (parseInt(linkStatusData.code) === 1004) {
          newObject.pan_adhar_link_status = "3";
          newObject.pan_link_status_message = "Invalid Pan Number";
        }
      } else {
        console.error("Error checking PAN-Adhar link status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleVehicleFastag = async () => {
    const ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    if (!VehicleFastag) {
      console.log("Invalid Vehicle Number");
      return;
    }

    // Construct the URL with the vehicle number as a query parameter
    const url = `${SERVER_URL}/fastag/fastag`;

    try {
      const checkVehicleFastagStatusResponse = await axios.get(url, {
        params: {
          vehiclenumber: VehicleFastag,
        },
      });

      console.log(checkVehicleFastagStatusResponse);

      const responseData = await checkVehicleFastagStatusResponse.data;
      console.log("API Response:", responseData);

      let newRows = [];

      if (
        responseData.success &&
        responseData.data &&
        responseData.data.response &&
        responseData.data.response[0] &&
        responseData.data.response[0].response &&
        responseData.data.response[0].response.vehicle &&
        responseData.data.response[0].response.vehicle.vehltxnList &&
        responseData.data.response[0].response.vehicle.vehltxnList.txn
      ) {
        responseData.data.response[0].response.vehicle.vehltxnList.txn.forEach(
          (transaction, index) => {
            const {
              vehicleRegNo,
              vehicleType,
              tollPlazaName,
              laneDirection,
              readerReadTime,
            } = transaction;

            newRows.push({
              id: index + 1,
              vehicleRegNo,
              vehicleType,
              tollPlazaName,
              laneDirection,
              readerReadTime,
            });
          }
        );
      } else {
        console.error("Unexpected response format:", responseData);
      }

      console.log("New Rows:", newRows);
      setRows(newRows);
      setRespData(true);
      setOverlay(true);
      //  else {
      //   console.error("Failed to fetch data. Status:", checkVehicleFastagStatusResponse.status);
      //   setRespData(false);
      //   setOverlay(true);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRespData(false);
      setOverlay(true);
    }
  };

  // Function to create data row
  function createData(
    id,
    vehicleRegNo,
    vehicleType,
    tollPlazaName,
    laneDirection,
    readerReadTime
  ) {
    return {
      id,
      vehicleRegNo,
      vehicleType,
      tollPlazaName,
      laneDirection,
      readerReadTime,
    };
  }

  const handlePrintEwb = async () => {
    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    try {
      const response = await fetch(
        SERVER_URL + "/eway/get_ewaybill_print" + `?ewb_no=${ewbNumber}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ACCESS_TOKEN,
          },
        }
      ).then((r) => r.blob());

      print({
        printable: URL.createObjectURL(response),
        type: "pdf",
        showModal: false,
      });
    } catch (error) {
      console.error("Error:", error);
    }
    setEwbNumber();
    setFlagEwb();
  };
  const handlePrintCewb = async () => {
    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    try {
      const response = await fetch(
        SERVER_URL + "/eway/get_cewb_print" + `?cewb_no=${cewbNumber}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ACCESS_TOKEN,
          },
        }
      ).then((r) => r.blob());

      print({
        printable: URL.createObjectURL(response),
        type: "pdf",
        showModal: false,
      });
    } catch (error) {
      console.error("Error:", error);
    }
    setCewbNumber();
    setFlagCewb();
  };

  const handleGenerateCewb = async () => {
    if (!ewb_nos || !vehicle_no || !place_of_consignor || !state_of_consignor) {
      alert("Fill mandatory fields");
      return;
    }

    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    try {
      const response = await fetch(SERVER_URL + "/eway/generate_cewb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ACCESS_TOKEN,
        },
        body: JSON.stringify({
          ewb_nos,
          vehicle_no,
          mode_of_transport,
          transporter_document_number,
          transporter_document_date,
          place_of_consignor,
          state_of_consignor,
        }),
      });

      if (!response.ok) {
        setOverlay(true);
      }
      if (response.ok) {
        const data = await response.json();
        setRespData(data.response.results.message);
        setOverlay(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setEwb_nos([]);
    setVehicle_no("");
    setTransporter_document_number("");
    setTransporter_document_date("");
    setPlace_of_consignor("");
    setState_of_consignor("");
  };
  const handleUpdateEwbTransporter = async () => {
    if (!ewb_nos || !transporterGst) {
      alert("Fill mandatory fields");
      return;
    }

    ACCESS_TOKEN = "Bearer " + sessionStorage.getItem("login");

    try {
      const response = await fetch(SERVER_URL + "/eway/update_transporter_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ACCESS_TOKEN,
        },
        body: JSON.stringify({
          eway_bill_number: ewbNumber,
          transporter_id: transporterGst,
        }),
      });

      if (!response.ok) {
        setOverlay(true);
      }
      if (response.ok) {
        const data = await response.json();
        setRespData(data.arg);
        setOverlay(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setEwbNumber("");
    setTransporterGst("");
  };

  const tableHeaders = [
    { label: "Sr No.", align: "left" },
    { label: "Vehicle Number", align: "right" },
    { label: "Vehicle Type", align: "right" },
    { label: "Toll Plaza Name", align: "right" },
    { label: "Lane Direction", align: "right" },
    { label: "Reader Read-Time", align: "right" },
  ];

  return (
    <>
      <div className="note">
        <span>
          Note :-&nbsp; Once the material is delivered, kindly stop the e-way
          bill extension.&nbsp; एक बार सामग्री डिलीवर हो जाने के बाद, कृपया ई-वे
          बिल एक्सटेंशन को रोक दें।
        </span>
      </div>
      <div className="titlebar">
        <div className={show ? "popup-container" : "disable-popup"}>
          <div className="user-detail">
            <IoMdContact className="pop-icon" />
            <p>{sessionObject.sessionVariables.username}</p>
          </div>
          <div className="btn-container">
            <div>
              <button className="popup-btn" onClick={showPopupChangePassword}>
                Change Password
              </button>
            </div>
            {flagChangePassword && (
              <Popup
                open={flagChangePassword}
                modal
                contentStyle={contentStyle}
                closeOnDocumentClick={false}
              >
                <div className="pop-up-container">
                  <div className="pop-up-header">
                    <label>Change Password</label>
                  </div>
                  <div className="i-holder">
                    <div className="i-label">
                      <label>
                        Old Password <span style={{ color: "red" }}>**</span>
                      </label>
                      <input
                        placeholder={`Old Password`}
                        type="password"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="i-label">
                      <label>
                        New Password <span style={{ color: "red" }}>**</span>
                      </label>
                      <input
                        placeholder={`New Password`}
                        type="password"
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="i-label">
                      <label>
                        Confirm Password{" "}
                        <span style={{ color: "red" }}>**</span>
                      </label>
                      <input
                        placeholder={`Confirm Password`}
                        type="text"
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="pop-up-actions">
                    <button
                      className="pop-up-button"
                      onClick={() => {
                        setFlagChangePassword();
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="pop-up-button"
                      onClick={handleSubmitChangePassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Popup>
            )}
            <Link to="/">
              <button className="popup-btn" onClick={logout}>
                Log Out
              </button>
            </Link>
          </div>
          <button className="popup-btn" onClick={showPopupRecharge}>
            Recharge
          </button>
          {flagRecharge && (
            <Popup
              open={flagRecharge}
              modal
              contentStyle={contentStyle}
              closeOnDocumentClick={false}
            >
              <div className="pop-up-container">
                <div className="pop-up-header">
                  <label>Recharge with QR code</label>
                </div>
                <div className="qr-holder">
                  <img
                    src="/assets/rechargeQR.jpg"
                    alt="QR Code"
                    style={{ height: "10rem" }}
                  />
                  <span>techitesy11@okhdfcbank</span>
                  <span style={{ width: "10rem", fontSize: "10px" }}>
                    After Recharge kindly call on 97236 01139 for your balance
                  </span>
                </div>
                <div className="pop-up-actions">
                  <button
                    className="pop-up-button"
                    onClick={() => {
                      setFlagRecharge();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Popup>
          )}
        </div>
        <div className="">
          <h1 className="title">SARAL eWAY</h1>
        </div>
        <div className="btn-holder">
          <div className="radio-holder">
            <span>AutoEwbExtension</span>
            <div>
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={autoExtension && autoExtension.auto_extension === 1}
                  onChange={handleOptionChange}
                />
                On
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="0"
                  checked={autoExtension && autoExtension.auto_extension === 0}
                  onChange={handleOptionChange}
                />
                Off
              </label>
            </div>
          </div>
          <div className="dropdown">
            <button onClick={toggleDropdown} className="dropbtn">
              EWB Actions
            </button>
            {dropdownOpen && (
              <div className="dropdown-content">
                <button onClick={showFlagEwb}>Print EWB</button>
                <button onClick={showFlagCewb}>Print CEWB</button>
                <button onClick={showFlagPanAadharLink}>
                  Pan/Aadhar Link{" "}
                </button>
                {(flagEwb ||
                  flagCewb ||
                  flagPanAadharLink ||
                  flagVehicleFastag) && (
                  <Popup
                    open={
                      toggleMode === "ewb" ||
                      toggleMode === "cewb" ||
                      toggleMode === "panAadharLink"
                    }
                    modal
                    contentStyle={contentStyle}
                    closeOnDocumentClick={false}
                  >
                    <div className="pop-up-container">
                      <div className="pop-up-header">
                        {toggleMode === "ewb"
                          ? "Print EWB"
                          : toggleMode === "panAadharLink"
                          ? "Pan Number"
                          : "Print CEWB"}
                      </div>
                      <label>
                        {toggleMode === "ewb"
                          ? "EWB Number "
                          : toggleMode === "panAadharLink"
                          ? "Pan Number "
                          : "CEWB Number "}
                      </label>
                      {toggleMode === "ewb" ? (
                        <input
                          placeholder={`Enter ewb number`}
                          value={ewbNumber}
                          onChange={(e) => setEwbNumber(e.target.value)}
                        />
                      ) : toggleMode === "panAadharLink" ? (
                        <input
                          placeholder={`Enter pan number`}
                          value={pan_no}
                          onChange={(e) => setPan_no(e.target.value)}
                        />
                      ) : (
                        <input
                          placeholder={`Enter cewb number`}
                          value={cewbNumber}
                          onChange={(e) => setCewbNumber(e.target.value)}
                        />
                      )}
                      <div className="pop-up-actions">
                        <button
                          className="pop-up-button"
                          onClick={() => {
                            setFlagEwb() ||
                              setFlagCewb() ||
                              setFlagPanAadharLink();
                          }}
                        >
                          Close
                        </button>
                        {toggleMode === "ewb" ? (
                          <button
                            className="pop-up-button"
                            onClick={handlePrintEwb}
                          >
                            Print
                          </button>
                        ) : toggleMode === "panAadharLink" ? (
                          <button
                            className="pop-up-button"
                            onClick={handlePanAadharLink}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className="pop-up-button"
                            onClick={handlePrintCewb}
                          >
                            Print
                          </button>
                        )}
                      </div>
                    </div>
                  </Popup>
                )}
                {/* <button onClick={showFlagVehicleFastag} disabled={true}>
                  Fastag
                </button> */}
                {flagVehicleFastag && (
                  <Popup
                    open={flagVehicleFastag}
                    modal
                    contentStyle={contentTableStyle}
                    closeOnDocumentClick={false}
                  >
                    <div
                      className="pop-up-container fastag-popup"
                      style={{ maxHeight: "80vh", overflow: "auto" }}
                    >
                      <>
                        <div className="pop-up-header">
                          <span>Fastag</span>
                          <i
                            className="fa-solid fa-xmark"
                            onClick={() => {
                              setFlagVehicleFastag();
                              setRows([]);
                              setVehicleFastag("");
                            }}
                          ></i>
                        </div>
                        <div className="flex-max">
                          <div className="flex-justify-fastag">
                            <label htmlFor="">
                              Vehicle Number{" "}
                              <span style={{ color: "red" }}>**</span>
                            </label>
                            <input
                              placeholder="Enter Vehicle Number"
                              value={VehicleFastag}
                              onChange={(e) => setVehicleFastag(e.target.value)}
                            />
                            <button
                              className="pop-up-button"
                              onClick={handleVehicleFastag}
                            >
                              Submit
                            </button>
                            <button
                              className="pop-up-button"
                              onClick={() => {
                                setVehicleFastag("");
                                setRows([]);
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </>
                      <div>
                        <>
                          <div className="flex-max">
                            <TableContainer
                              className="tableContainer"
                              component={Paper}
                              style={{ maxHeight: "50vh", overflow: "auto" }}
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    {tableHeaders.map((header, index) => (
                                      <TableCell
                                        key={index}
                                        align={header.align}
                                      >
                                        {header.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {respData ? (
                                    rows.map((row) => (
                                      <TableRow
                                        key={row.id}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {row.id}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.vehicleRegNo}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.vehicleType}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.tollPlazaName}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.laneDirection === "S"
                                            ? "South"
                                            : row.laneDirection === "N"
                                            ? "North"
                                            : row.laneDirection === "W"
                                            ? "West"
                                            : row.laneDirection === "E"
                                            ? "East"
                                            : row.laneDirection}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.readerReadTime}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colSpan={tableHeaders.length}
                                        align="center"
                                      >
                                        No data available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </>
                      </div>
                    </div>
                  </Popup>
                )}

                <button onClick={showFlagGenerateCewb}>Generate CEWB</button>
                {flagGenerateCewb && (
                  <Popup
                    open={flagGenerateCewb}
                    modal
                    contentStyle={contentStyle}
                    closeOnDocumentClick={false}
                  >
                    <div className="pop-up-container">
                      {overlay ? (
                        <>
                          {respData ? (
                            <div className="pop-up-header">Success</div>
                          ) : (
                            <div className="pop-up-header">Error</div>
                          )}
                          {respData ? (
                            <div className="flex-max">
                              <span className="sign-in-name">
                                CEWB Generated successfully
                              </span>
                              <div>
                                <span className="sign-in-name">CEWB No. </span>
                                {respData.cEwbNo}
                              </div>
                              <div>
                                <span className="sign-in-name">
                                  Generated Date{" "}
                                </span>
                                {respData.cEwbDate}
                              </div>
                            </div>
                          ) : (
                            <div className="sign-in-name">
                              Something went wrong
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="pop-up-header">Generate CEWB</div>
                          <div className="flex-max">
                            {fields.map((field, index) => (
                              <div key={index} className="flex-justify">
                                <label
                                  htmlFor={field.name}
                                  dangerouslySetInnerHTML={{
                                    __html: field.label,
                                  }}
                                ></label>
                                <input
                                  placeholder={field.placeholder}
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <div className="pop-up-actions">
                        <button
                          className="pop-up-button"
                          onClick={() => {
                            setFlagGenerateCewb();
                            overlay && window.location.reload();
                          }}
                        >
                          Close
                        </button>
                        {!overlay && (
                          <button
                            className="pop-up-button"
                            onClick={handleGenerateCewb}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </Popup>
                )}
                <button onClick={showFlagUpdateEwbTransporter}>
                  Update EWB Transporter
                </button>
                {flagUpdateEwbTransporter && (
                  <Popup
                    open={flagUpdateEwbTransporter}
                    modal
                    contentStyle={contentStyle}
                    closeOnDocumentClick={false}
                  >
                    <div className="pop-up-container">
                      {overlay ? (
                        <>
                          {respData ? (
                            <div className="pop-up-header">Success</div>
                          ) : (
                            <div className="pop-up-header">Error</div>
                          )}
                          {respData ? (
                            <div className="flex-max">
                              <span className="sign-in-name">
                                Transporter EWB updated successfully
                              </span>
                              <div>
                                <span className="sign-in-name">EWB No. </span>
                                {respData.eway_bill_number}
                              </div>
                              <div>
                                <span className="sign-in-name">
                                  Transporter ID{" "}
                                </span>
                                {respData.transporter_id}
                              </div>
                              <div>
                                <span className="sign-in-name">
                                  User Gst ID{" "}
                                </span>
                                {respData.userGstin}
                              </div>
                            </div>
                          ) : (
                            <div className="sign-in-name">
                              Something went wrong
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="pop-up-header">
                            Update EWB Transporter
                          </div>
                          <div className="flex-max">
                            <div className="i-holder">
                              <div className="i-label">
                                <label>
                                  EWB number{" "}
                                  <span style={{ color: "red" }}>**</span>
                                </label>
                                <input
                                  placeholder={`Enter EWB number`}
                                  value={ewbNumber}
                                  onChange={(e) => setEwbNumber(e.target.value)}
                                />
                              </div>
                              <div className="i-label">
                                <label>
                                  Transporter GST number{" "}
                                  <span style={{ color: "red" }}>**</span>
                                </label>
                                <input
                                  placeholder={`Transporter GST number`}
                                  value={transporterGst}
                                  onChange={(e) =>
                                    setTransporterGst(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="pop-up-actions">
                        <button
                          className="pop-up-button"
                          onClick={() => {
                            setFlagUpdateEwbTransporter();
                            overlay && window.location.reload();
                          }}
                        >
                          Close
                        </button>
                        {!overlay && (
                          <button
                            className="pop-up-button"
                            onClick={handleUpdateEwbTransporter}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </Popup>
                )}
              </div>
            )}
          </div>
          <div className="login">
            {/* <button className="popup-btn" onClick={() => {
            setShow(false);
            setDropdownOpen(false);
            setRecharge(true)
          }}>
            Recharge Wallet
          </button> */}
            <Modal show={recharge} onClose={() => setRecharge(false)} />
            <span className="sign-in-name">
              {sessionObject.sessionVariables.username}
            </span>
            <IoMdContact className="icon" onClick={showPopup} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Titlebar;
