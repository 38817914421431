import React, { useState } from 'react'
import './Modal.css'
import axios from 'axios';
import { message } from 'antd';
import { SERVER_URL } from '../config/config';

const Modal = ({ show, onClose }) => {
    var ACCESS_TOKEN = null

    const [amount, setAmount] = useState();

    const closeModal = () => {
        setAmount('');
        onClose();
    }

    const formData = new FormData();
    formData.append('amount', amount);

    const handlePayment = async (response) => {
        ACCESS_TOKEN = "Bearer " + sessionStorage.getItem('login');
        const formData = new FormData();
        formData.append("razorpay_payment_id", response.razorpay_payment_id);
        formData.append("razorpay_order_id", response.razorpay_order_id);
        formData.append("razorpay_signature", response.razorpay_signature);
        try {
            const data = await axios.post(SERVER_URL + "/payments/verify-payment", formData, {
                headers: { Authorization: ACCESS_TOKEN },
            });
            if (data.data.status === "Payment Verified") {
                message.success('Payment Successful');
                onClose();
            }
        } catch (error) {
            console.log(error); // eslint-disable-line
            message.error('Payment Failed');
        }
    };

    const handlePaymentFailed = (response) => {
        message.error('Payment Failed');
        onClose();
        console.log(response); // eslint-disable-line
    };

    const handleCreatePayment = async () => {
        try {
            ACCESS_TOKEN = "Bearer " + sessionStorage.getItem('login');
            const response = await axios.post(SERVER_URL + "/payments/razorpay", formData, {
                headers: { Authorization: ACCESS_TOKEN },
            });
            const options = {
                key: process.env.RAZORPAY_API_KEY,
                amount: amount,
                currency: "INR",
                name: "Saral eWay",
                description: `Recharge Wallet Amount ${amount}`,
                image: 'https://picsum.photos/200/300.jpg',
                order_id: response.data.id,
                handler: handlePayment,
                theme: {
                    color: "#3399cc",
                },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", handlePaymentFailed);

            rzp1.open();
        } catch (error) {
            console.log(error); // eslint-disable-line
            message.error('Payment Failed');
        }
    }

    return (
        <div>
            {show && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2 className='modal-heading'>
                            Recharge Wallet
                            <span className="close-button" onClick={closeModal}>
                                &times;
                            </span>
                        </h2>
                        <div className="modal-body">
                            {/* enter amount */}
                            <input type="number" placeholder="Enter Amount" className="amount-input" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <button className="recharge" onClick={handleCreatePayment}>Pay</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Modal
